<template>
	<div class="app-main__inner">
		<div class="container">

			<div class="row justify-content-end mt-4">
				<div class="col-12 flex flex-col md:flex-row md:justify-between">
					<h2 class="text-xl font-medium text-gray-900">{{ $t('companies') }}</h2>
					<div>
					</div>
					<div class="flex md:justify-end">
						<div class="form-group">
							<div class="input-icons">
								<i class="iconly-brokenSearch"></i>
								<input type="text" @input="searchInputTrigger" v-model="searchName"
									   class="text-sm h-8 form-control custom-search" v-bind:placeholder="$t('search')">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="main-card mb-3 bg-white">
				<div class="card-body pb-5">
					<div class="table-responsive" v-loading="loading">
						<table style="width: 100%;" id="exampl" class="table table-hover table-striped table-bordered">
							<thead>
							<tr>
								<th>
									<div class="d-flex justify-content-between text-xs">{{ $t('companyName') }}<span><i
										class="lnr-arrow-up cursor-pointer"></i><i
										class="lnr-arrow-down cursor-pointer"></i></span></div>
								</th>
								<th>
									<div class="d-flex justify-content-between text-xs">{{
											$t('companyNumber')
										}}<span><i class="lnr-arrow-u cursor-pointer"></i><i
											class="lnr-arrow-dow cursor-pointer"></i></span></div>
								</th>
								<th class="text-xs">Actions</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(user, i) in GET_USERS_COMPANY.results" :key="i">
								<td class="text-xs font-light">
									<el-tooltip :content="$t('enterCompanyInfo')" placement="top">
										<span class="cursor-pointer" @click="getCompanyUserID(user.id)">{{
												user.company.name | capitalizeNames
											}}</span>
									</el-tooltip>
								</td>
								<td class="text-xs font-light">{{ user.company.company_id || null }}</td>
								<td class="flex space-x-4">
									<el-tooltip :content="$t('enterCompanyInfo')" placement="top">
										<span @click="getCompanyUserID(user.id)"
											  class="text-base cursor-pointer iconly-brokenEdit icon-color"></span>
									</el-tooltip>
									<el-tooltip :content="$t('noteHovering')" placement="top">
										<span @click="checkCompanyNote(user.id, 'Company')"
											  class="text-base cursor-pointer iconly-brokenPaper-Plus icon-color"></span>
									</el-tooltip>
									<el-tooltip :content="$t('deleteHovering')" placement="top">
										<span @click="disconnectCompany(user.id)"
											  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>
									</el-tooltip>
								</td>
							</tr>
							<!-- <tr v-if="usersCompany.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
							</tbody>
						</table>
					</div>
					<div class="float-right mb-5" v-if="GET_USERS_COMPANY.results">
						<paginate
							:page-count="total_pages"
							:click-handler="changePageNumber"
							prev-text="<"
							next-text=">"
							:container-class="'pagination'">
						</paginate>
					</div>
					<!-- <board ref="board" :board="activeBoard" v-show="activeBoard" /> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Paginate from 'vuejs-paginate'
import debounce from 'lodash/debounce'
import bus from './../../bus'
import {Tooltip} from 'element-ui'

Vue.use(Tooltip)

export default {
	name: 'Dashboard',
	components: {
		paginate: Paginate,
		// Tooltip
	},
	data: function () {
		return {
			loading: true,
			total_pages: 1,
			searchName: ''
		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_USER_FIRM_ID: 'customer/GET_USER_FIRM_ID',
			GET_DASHBOARD_METRICS: 'accountant/GET_DASHBOARD_METRICS',
			GET_USERS_COMPANY: 'assistanceAccountant/GET_ASSISTANCE_COMPANY'
		})
	},
	mounted() {
		this.getConnectCompany('')
	},
	methods: {
		searchInputTrigger: debounce(function (event) {
			let query = ''
			if (this.searchName) {
				query += `&search=${this.searchName}`
			}
			this.getConnectCompany(query)
		}, 500),
		filterCompanies(event) {
			// console.log(event)
			this.$store.commit('assistanceAccountant/FILTER_COMPANY', event.target.value)
		},
		disconnectCompany(user) {
			window.Bus.$emit('disconnect-company', user)
		},
		getAccountantMetrics(partnerId) {
			const URL = partnerId ? this.$services.endpoints.GET_ACCOUNTING_FIRM_METRICS + `${JSON.parse(partnerId).partner}` : this.$services.endpoints.GET_ACCOUNTANT_METRICS
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_ACCOUNTANT_METRICS)
			this.$store.dispatch('accountant/getAccountantMetrics', {URL})
			// .then(res => {
			//     if (res.data.success) {
			//         this.dashboardMetrics = res.data.data
			//     }
			// }).catch((_) => {
			// })
		},
		checkCompanyNote(userId, agent) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.MANAGERS_NOTE + `${userId}/`)
			const URL = this.$services.endpoints.COMPANY_NOTES + `?user_id=${userId}`
			this.$store.dispatch('accountant/checkCompanyNote', URL)
				.then(res => {
					bus.$emit('hide-notify-me', '')
					if (res.data.success) {
						if (res.data.data.length > 0) {
							bus.$emit('open-sticky', {
								userId,
								updateUser: true,
								agent,
								noteID: res.data.data[0].id,
								message: res.data.data[0].message
							})
							// this.updateUser = true
							// this.createNote = false
							// this.userIdNote = userId
							// this.userAgent = agent
							// this.noteId = res.data.data[0].id
							// this.$refs.board.addNote('neutral', res.data.data[0].message)
						} else {
							bus.$emit('open-sticky', {
								userId,
								updateUser: false,
								agent,
								message: ''
							})
							// this.createNote = true
							// this.updateUser = false
							// this.userIdNote = userId
							// this.companyID = userId
							// this.userAgent = agent
							// this.$refs.board.addNote('neutral')
						}
					}
				}).catch((_) => {

			})
		},
		getUsersCompany() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_USER_BY_MANAGER)
			this.$store.dispatch('accountant/getUsersCompany')
				.then(_ => {
					// console.log('Accountant com:', res)
					// if (res.data.success) {
					//     this.usersCompany = res.data.data
					//     this.copyAllCompanies = res.data.data
					// }
				}).catch(err => {
				// console.log(err.response)
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getUsersCompany()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								// window.location.reload()
							})
					}
				}
			})
		},
		changePageNumber(num) {
			this.$store.dispatch('assistanceAccountant/getAssistanceConnectCompany', {URL: this.$services.endpoints.ALL_USER_COMPANIES + `?page=${num}`}).then(response => {
				if (response.data.count > 20) {
					this.total_pages = Math.ceil(response.data.count / 20)
				} else {
					this.total_pages = 1
				}
			})
		},
		getConnectCompany(query = '') {
			this.loading = true
			let params = '?limit=20'
			if (query) {
				params += query
			}
			this.$store.dispatch('assistanceAccountant/getAssistanceConnectCompany', {URL: this.$services.endpoints.ALL_USER_COMPANIES + params})
				.then(response => {
					this.loading = false
					if (response.data.count > 20) {
						this.total_pages = Math.ceil(response.data.count / 20)
					} else {
						this.total_pages = 1
					}
				}).catch(() => {
				this.loading = false
			})
		},
		ascendingOrderName() {
			this.$store.commit('accountant/ASCENDING_ORDER_NAME')
		},
		descendingOrderName() {
			this.$store.commit('accountant/DESCENDING_ORDER_NAME')
		},
		getCompanyUserID(userID) {
			this.$store.commit('customer/SET_USER_COMPANY_ID', userID)
			setTimeout(() => {
				// window.location.href = '/automatisera'
				window.open('/automatisera', '_blank')
			}, 1000)
		},
		// boardTitle () {
		//     return 'Am Django'
		// },
		// createBoard (initial) {
		//     const board = {
		//         title: this.boardTitle(),
		//         notes: [],
		//         initial: initial
		//     }
		//     return board
		// },
		// showStickyCard () {
		//     this.$refs.board.addNote('neutral')
		// },
	},

}
</script>

<style scoped>
.input-icons i {
	position: absolute;
	margin: 7px;
	padding-top: 5px;
}

.input-icons {
	width: 100%;
	margin-bottom: 10px;
}

.custom-search {
	padding-left: 1.5rem !important;
}
</style>
